.portfolio__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    border-radius: 1rem;
    border: 1px solid transparent;
    padding: 1rem;
    transition: var(--transition);
    display: flex;
    flex-direction: column;
}

.portfolio__item:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.portfolio__item-image {
    border-radius: 1rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 0.5rem 0 0.75rem;
}

.portfolio__item-cta {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
}

.portfolio__item-cta a {
    flex-grow: 1;
    font-size: 0.8rem; /* Adjust the font size as needed */
    margin-right: 0.5rem; /* Add margin between buttons */
}

/* <======media queries (medium devices)=======>*/

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 1rem;
    }
}

/* <======media queries (small devices)=======>*/

@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
        justify-content: center;
    }

    .portfolio__item h3 {
        font-size: 0.9rem;
        margin: 0.5rem 0 0.5rem;
    }

    .portfolio__item-cta a {
        font-size: 0.7rem; /* Adjust the font size for small devices */
        margin-right: 0.5rem; /* Add margin between buttons */
    }
}

