header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}
.header__container{
    text-align: center;
    height: 100%;
    position: relative;

}

 /*<===== CTA =====>*/

 .cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
 }
  /*<===== social icons =====>*/
  .header__social {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;

  }
  .header__social::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
  }

  /*<===== me image =====>*/
  .me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
  }
   /*<===== scroll down =====>*/
   .scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 200;
    font-size: 0.9rem;

   }

   /* <======media queries (medium devices)=======>*/

@media screen and (max-width: 1024px){

  header{
    height: 68vh;

  }
}
@media screen and (max-width: 1024px){

  header{
    height: 45rem;

  }
}

@media screen and (max-width: 850px){

  header{
    height: 80vh;

  }
}





 /* <======media queries (small devices)=======>*/

 @media screen and (max-width: 600px){
  header {
    height: 100vh;
  }
  .header__social,
  .scroll__down{
    display: none;
  }
 }

